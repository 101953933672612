.paginator > button{
  @apply border border-gray-light rounded-full hover:bg-gray-light disabled:bg-gray-lightest disabled:text-white disabled:border-gray-lightest;
}
.paginator > i{
  @apply flex justify-center items-center;
}
.paginator > button {
  height: 40px;
  width: 40px;
}
.paginator *:not(:last-child){
  @apply mr-2;
}
.arrow-buttons{
  @apply pt-1;
}
.active-page{
  @apply text-white bg-gray-darkest;
}