header {
  border-width: 1px;
  @apply z-50 bg-white border-gray-lighter shadow-md lg:py-1;
}

.header-menu-mobile {
  @apply -left-3/4 top-0 absolute h-screen w-3/4 max-w-xs bg-white shadow-2xl transition-all duration-500;
}

.header-menu-mobile-open {
  @apply left-0 z-40;
}

.header-profile {
  @apply bg-gray-dark text-white;
}

.header-menu>* {
  @apply lg:my-0 py-3 lg:py-1 px-5 lg:px-2 lg:rounded-lg lg:rounded-bl-none border border-white;
}

.header-menu>*:not(:last-child) {
  @apply lg:mr-6;
}

.header-submenu {
  left: -1px;
  top: 100%;
  @apply min-w-fit lg:absolute flex flex-col lg:rounded-b-lg shadow-inner lg:shadow-lg border-b bg-white lg:border lg:border-t-2 lg:border-t-white border-gray-light;
}

.header-submenu-dropdown {
  @apply top-0 left-full min-w-max lg:absolute flex flex-col lg:rounded-lg shadow-inner lg:shadow-lg border-b bg-white lg:border border-gray-light;
}

.header-submenu>*, .header-submenu-dropdown>* {
  @apply px-5 py-3 lg:px-3 lg:py-2;
}

.header-submenu>* {
  @apply last:lg:pb-0;
}

.header-submenu-dropdown>* {
  @apply last:pb-3 text-gray-dark;
}

.header-submenu>*:last-child {
  @apply lg:mb-3;
}

.user-profile {
  @apply flex items-center font-semibold;
}

.user-profile p{
  max-width: 20rem;
  @apply whitespace-nowrap overflow-hidden text-ellipsis;
}

.user-profile i {
  @apply border-2 p-2 text-20 mr-3 rounded-full;
}

.user-profile+.header-submenu {
  @apply rounded-lg bg-gray-darker text-white mt-3;
}

.sticky {
  position: fixed;
  top: 0;
}

.header-canvas-close {
  @apply absolute w-screen h-screen top-0 left-0 bg-black opacity-50;
}

.header-active {
  @apply bg-gray-darker text-white font-semibold;
}

.header-active2 {
  @apply bg-gray text-white font-semibold;
}

.open-submenu {
  @apply lg:border-gray-light;
}