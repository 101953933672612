@tailwind base;

@layer base {

  html,
  body {
    @apply font-poppins text-gray-dark text-15;
  }

  sup {
    @apply text-red;
  }

  h1 {
    @apply text-30 font-medium text-gray-darker m-0;
  }

  h2 {
    @apply text-17 font-medium text-gray;
  }

  h3 {
    @apply font-semibold;
  }

  hr {
    @apply text-gray-light;
  }

  input {
    border-width: 1px;
    @apply py-2 px-4 border-gray-light rounded-lg mb-5 disabled:bg-gray-lightest disabled:text-gray text-15;
  }

  label {
    @apply text-gray-darker;
  }

  input[type='checkbox'] { 
    @apply accent-gray-dark;
  }
}