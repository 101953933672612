@font-face {
  font-family: 'icomoon';
  src:  url('../fonts/icoMoon/icomoon.eot?c5fri5');
  src:  url('../fonts/icoMoon/icomoon.eot?c5fri5#iefix') format('embedded-opentype'),
    url('../fonts/icoMoon/icomoon.ttf?c5fri5') format('truetype'),
    url('../fonts/icoMoon/icomoon.woff?c5fri5') format('woff'),
    url('../fonts/icoMoon/icomoon.svg?c5fri5#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-filter:before {
  content: "\e911";
}
.icon-lock:before {
  content: "\e90f";
}
.icon-warning:before {
  content: "\e90e";
}
.icon-error-404:before {
  content: "\e90d";
}
.icon-no-results:before {
  content: "\e90c";
}
.icon-logout:before {
  content: "\e90b";
}
.icon-menu:before {
  content: "\e90a";
}
.icon-dots:before {
  content: "\e908";
}
.icon-left-arrow:before {
  content: "\e909";
}
.icon-close:before {
  content: "\e907";
}
.icon-save:before {
  content: "\e906";
}
.icon-loading:before {
  content: "\e905";
}
.icon-details:before {
  content: "\e904";
}
.icon-right-arrow:before {
  content: "\e902";
}
.icon-arrow-down:before {
  content: "\e903";
}
.icon-logo:before {
  content: "\e901";
  color: #4d4e58;
}
.icon-send:before {
  content: "\e900";
}
.icon-home:before {
  content: "\e910";
}
.icon-user:before {
  content: "\e971";
}
.icon-search:before {
  content: "\e986";
}
.icon-delete:before {
  content: "\e9ad";
}
.icon-pause:before {
  content: "\ea1d";
}
.icon-refresh:before {
  content: "\ea2e";
}
