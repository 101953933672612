table {
  width: 100%;
  border-width: 1px;
  @apply border border-gray-light;
}

/* Header content */
thead {
  border-bottom-width: 1px;
  @apply border-gray-light text-13;
}

th {
  @apply text-gray-darker font-semibold mb-6 py-7 pr-5 text-left;
}

th:first-child {
  @apply pl-10;
}

th:last-child {
  @apply pr-10;
}

/* Body content */
tbody::after,
tbody::before {
  content: " ";display: table-cell;
  @apply pt-2;
}

tbody tr {
  @apply hover:bg-gray-lighter;
}

td {
  @apply py-2 pr-5;
}

td:first-child {
  @apply pl-10;
}

td:last-child {
  @apply pr-10;
}

.card-list {
  @apply flex flex-col border border-gray-lighter px-3 py-4 rounded-xl;
}

.card-list:not(:last-child) {
  @apply mb-5;
}

.card-list hr {
  @apply my-2;
}

.card-list div>p:first-child {
  @apply text-gray-darker font-semibold;
}

.card-list div{
  @apply border-b-2 border-gray-lighter pb-2 ;
}

.card-list > div:not(:first-child){
  @apply pt-2;
}

.card-list a {
  @apply bg-gray-dark text-white text-center py-2 rounded-lg mt-3;
}

.card-list button {
  @apply bg-gray-dark text-white text-center py-2 rounded-lg mt-3;
}

.card-list .button-eliminate {
  @apply bg-red;
}
