
.popup {
  height: 100%;
  width: 100%;
  @apply top-0 left-0 fixed z-50 flex;
}

.popup-content {
  z-index: 100;
  @apply max-w-xs sm:max-w-md md:max-w-screen-sm bg-white relative m-auto h-fit rounded-2xl p-6 sm:p-8 md:p-12;
}

.popup-message{
  @apply text-sm sm:text-xl md:text-24;
}

.black-canvas {
  height: 100%;
  width: 100%;
  @apply top-0 left-0 bg-black-light absolute opacity-80;
}