@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@200;300;400;500;600;700&display=swap');

/*iconos*/
@import url('icons.css');

@import "tailwindcss/components";
@import "tailwindcss/utilities";
@import "./custom-base-styles.css";

/* Generals */
@import "./Buttons.css";
@import "./DivisionComponent.css";
@import "./Forms.css";
@import "./Loader.css";
@import "./NoExist.css";
@import "./Table.css";
@import "./Paginator.css";
/* Components */
@import "./Header.css";
@import "./Home.css";
@import "./Login.css";
@import "./OrderDetail.css";
@import "./Popup.css";