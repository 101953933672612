.division{
  @apply flex flex-col lg:flex-row border-b pb-5 lg:pb-7 border-gray;
}
* > .division ~ .division{
  @apply pt-5 lg:pt-7;
}

.division input {
  width: 100%;
  @apply mb-0;
}

.division label {
  width: 100%;
  @apply relative flex justify-center items-center;
}

.division i {
  @apply absolute right-5 text-gray;
}

.division textarea{
  min-height: 500px;
  @apply basis-12;
}