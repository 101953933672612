.button-base{
  @apply px-8 py-3 font-semibold text-center;
}
.button-dark{
  @apply button-base rounded-full text-white hover:bg-gray-darkest bg-gray-darker;
}
.button-submit{
  @apply button-base rounded-full hover:bg-yellow-light bg-yellow;
}
.button-delete{
  border-bottom-width: 1px;
  @apply font-semibold border-b-2 hover:text-error text-red;
}
.button-delete-2{
  @apply text-white font-semibold hover:bg-error bg-red;
}
.button-save{
  border-bottom-width: 1px;
  @apply font-semibold border-b-2 hover:text-blue text-blue-light;
}
.button-logout{
  border-bottom-width: 1px;
  @apply bg-white text-gray-dark w-full rounded-lg py-2 mt-5;
}
.button-cancel{
  @apply font-semibold border-2;
}
.button-close{
  @apply absolute top-6 right-6; 
}

.action-buttons{
  @apply flex justify-end items-center mt-5;
}
.action-buttons > *:not(:last-child){
  @apply mr-8;
}

.action-buttons-2{
  gap: 1rem;
  @apply flex justify-between mt-5 ;
}

.action-buttons-2 >*{
  width: 100%;
  @apply py-2 rounded-lg;
}