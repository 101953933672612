.login {
  @apply h-screen;
}

.login .logo {
  @apply mb-5;
}

.login h1{
  @apply mb-5 font-normal;
}

.login h1, .login span{
  @apply text-center;
}

.login form{
  @apply flex flex-col;
}
